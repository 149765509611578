@font-face {
  font-family: "Madera Regular";
  src: local("maderaRegular"), url("../public/assets/fonts/maderaRegular.otf") format("opentype");
}

@font-face {
  font-family: "Madera Bold";
  src: local("maderaBold"), url("../public/assets/fonts/maderaBold.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Next";
  src: local("avenirNext"), url("../public/assets/fonts/avenirNext.otf") format("opentype");
}

@font-face {
  font-family: "Barlow Condensed";
  src: local("barlowCondensed"), url("../public/assets/fonts/barlowCondensed.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("gotham"), url("../public/assets/fonts/gotham.ttf") format("truetype");
}

@font-face {
  font-family: "Edenred";
  src: local("edenred"), url("../public/assets/fonts/edenred.otf") format("opentype");
}

@font-face {
  font-family: "Ubuntu";
  src: local("ubuntu"), url("../public/assets/fonts/ubuntu.ttf") format("truetype");
}

/* Not for a client - replacement option for gotham */
@font-face {
  font-family: "Kanit";
  src: local("kanit"), url("../public/assets/fonts/kanit.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


a {
  text-decoration: none !important;
}

.defaultButton {
  color: black!important;
  margin-right: 8px!important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-right {
  text-align: right !important;
}